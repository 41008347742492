<template>
  <div>
    <PageHeader fileName="header-19">
      <h3>{{ lang.title }}</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 d-flex flex-column">
            <h2 class="mb-4" v-html="lang.section1.header" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ lang.section1.paragraph1 }}
            </h6>
          </div>
        </div>
      </div>
    </section>

    <SectionHeader fileName="packages-group-exp">
      <h1 v-html="lang.group_exp.header" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row gap-y-4">
          <div class="col-lg-5">
            <h4 class="text-tertiary">{{ lang.group_exp.title }}</h4>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6>{{ lang.group_exp.choose }}</h6>

            <div class="d-flex flex-column gap-2">
              <a
                class="btn btn-block btn-tertiary text-center"
                :href="btn.anchor"
                v-for="(btn, bi) of lang.group_exp.buttons"
                :key="bi"
              >
                {{ btn.title }}
              </a>
            </div>
          </div>
          <div
            class="col-12 pt-6"
            v-for="(table, ti) of lang.group_exp.tables"
            :key="ti"
          >
            <div class="packages-table" :id="table.id">
              <div class="head">
                <h5>{{ table.title }}</h5>
                <i class="fa fa-arrow-right text-tertiary fs-5"></i>
                <h5>{{ table.cost }}</h5>
              </div>
              <div class="body">
                <div
                  class="grid-row"
                  :class="{ 'full-width': !item.title }"
                  v-for="(item, i) of table.items"
                  :key="i"
                >
                  <h6 v-if="item.title">{{ item.title }}</h6>
                  <div>
                    <i class="fa fa-play text-tertiary" v-if="item.title"></i>
                    <div v-html="item.description" />
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center my-5">
              <a
                href="#form"
                class="btn text-center px-5 d-inline-block"
                :class="`btn-tertiary`"
              >
                {{ lang.cta }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SectionHeader fileName="packages-season-exp">
      <h1 v-html="lang.season_exp.header" />
    </SectionHeader>

    <section class="py-7 bg-dark">
      <div class="container">
        <div class="row gap-y-4">
          <div class="col-lg-5">
            <h4 class="text-tertiary">{{ lang.season_exp.title }}</h4>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6>{{ lang.season_exp.choose }}</h6>

            <div class="d-flex flex-column gap-2">
              <a
                class="btn btn-block btn-tertiary text-center"
                :href="btn.anchor"
                v-for="(btn, bi) of lang.season_exp.buttons"
                :key="bi"
              >
                {{ btn.title }}
              </a>
            </div>
          </div>
          <div
            class="col-12 pt-6"
            v-for="(group, gi) of lang.season_exp.groups"
            :key="gi"
            :id="group.id"
          >
            <h4 class="text-tertiary text-center mb-5">{{ group.title }}</h4>

            <template v-for="(table, ti) of group.tables" :key="ti">
              <div class="packages-table">
                <div class="head">
                  <h5>{{ table.title }}</h5>
                  <i class="fa fa-arrow-right text-tertiary fs-5"></i>
                  <h5>{{ table.cost }}</h5>
                </div>
                <div class="body">
                  <div
                    class="grid-row"
                    :class="{ 'full-width': !item.title }"
                    v-for="(item, i) of table.items"
                    :key="i"
                  >
                    <h6 v-if="item.title">{{ item.title }}</h6>
                    <div>
                      <i class="fa fa-play text-tertiary" v-if="item.title"></i>
                      <div v-html="item.description" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center my-5">
                <a
                  href="#form"
                  class="btn text-center px-5 d-inline-block"
                  :class="`btn-tertiary`"
                >
                  {{ lang.cta }}
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-tertiary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'meet_our_team' }"
            >
              <small class="m-0"> {{ $lang("footer.card1.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0"> {{ $lang("footer.card2.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'extra_activities' }"
            >
              <small class="m-0"> {{ $lang("footer.card3.body") }} </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-7" theme="tertiary" id="form">
      {{ lang.contact_form?.location }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    SectionHeader,
    CardButton,
  },
  computed: {
    lang() {
      return this.$lang("our_packages", true);
    },
  },
};
</script>

<style></style>
